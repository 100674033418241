.emp-profile .bgcard {
    background-image: url("../../assets//images//bgcard.avif");
    background-image: cover;
    background-position: center;
    border-radius: 20px;
  }
  
  .hierarchy .empcard {
    border: 1px solid var(--hieborder);
    border-radius: 10px;
    background-color: var(--hiecard-bg);
    width: 11rem;
    font-weight: 500;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
  }
  
  .empcardside2 {
    z-index: 1;
  }
  .empcardside3 {
    z-index: 1;
  }
  .empcardside {
    z-index: 1;
  }
  
  .empcardside {
    position: absolute;
    border: 1px solid var(--hieborder);
    border-radius: 10px;
    padding: 0px 10px;
    background-color: var(--hiecard-bg);
    width: 15rem;
    font-weight: 500;
    // height: 105px;
    left: 12.2rem;
    top: -0.3rem;
  }
  
  .empcardside p {
    font-size: 0.7rem;
  }
  
  .empcardside img {
    width: 4rem;
    border-radius: 5px;
    border: 2px solid var(--hieborder);
  }
  
  .hierarchy .arrow {
    position: relative;
    width: 12px;
    height: 2px;
    background-color: var(--hieborder);
    rotate: 90deg;
    top: 5px;
    left: 81px;
  }
  
  .hierarchy .arrow::before {
    content: "";
    position: absolute;
    top: 45%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid var(--hieborder);
    transform: translateY(-44%);
  }
  
  .hierarchy .arrow1 {
    position: relative;
    width: 12px;
    height: 2px;
    background-color: var(--hieborder);
    rotate: 90deg;
    top: 24px;
    left: -106px;
  }
  
  .hierarchy .arrow1::before {
    content: "";
    position: absolute;
    top: 45%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid var(--hieborder);
    transform: translateY(-44%);
  }
  .arrowside {
    rotate: 0deg;
    position: absolute;
    left: 11rem;
    width: 12px;
    height: 3px;
    background-color: var(--hieborder);
    top: 45px;
  }
  
  .arrowside::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid var(--hieborder);
    transform: translateY(-44%);
  }
  
  .hierarchy .arrowsided {
    position: relative;
    width: 12px;
    height: 2px;
    background-color: var(--hieborder);
    rotate: 90deg;
    top: 1px;
    left: 318px;
  }
  
  .hierarchy .arrowsided::before {
    content: "";
    position: absolute;
    top: 70%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 8px solid var(--hieborder);
    transform: translateY(-58%);
  }
  
  .hierarchy .arrowsided2 {
    position: relative;
    width: 12px;
    height: 2px;
    background-color: var(--hieborder);
    rotate: 1deg;
    top: -116px;
    left: 431px;
  }
  
  .hierarchy .arrowsided2::before {
    content: "";
    position: absolute;
    top: 70%;
    right: -8px;
    /* Adjust the distance of the arrow from the end of the line */
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-bottom: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-left: 8px solid var(--hieborder);
    /* Adjust the color and width of the arrowhead */
    transform: translateY(-58%);
  }
  
  .arrowside1 {
    rotate: 0deg;
    position: absolute;
    left: 11rem;
    width: 12px;
    height: 2px;
    background-color: var(--hieborder);
    // rotate: 90deg;
    top: -16px;
    // left: 42px;
  }
  .arrowside1::before {
    content: "";
    position: absolute;
    top: 70%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-bottom: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-left: 8px solid var(--hieborder);
    /* Adjust the color and width of the arrowhead */
    transform: translateY(-58%);
  }
  .arrowside2 {
    rotate: 91deg;
    position: absolute;
    left: -13rem;
    width: 12px;
    height: 2px;
    background-color: var(--hieborder);
    // rotate: 90deg;
    top: 37px;
    // left: 42px;
  }
  
  .arrowside2::before {
    content: "";
    position: absolute;
    top: 70%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-bottom: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-left: 8px solid var(--hieborder);
    /* Adjust the color and width of the arrowhead */
    transform: translateY(-58%);
  }
  .arrowside3 {
    rotate: 0deg;
    position: absolute;
    left: -14.8rem;
    width: 12px;
    height: 2px;
    background-color: var(--hieborder);
    // rotate: 90deg;
    top: -30px;
    // left: 42px;
  }
  .arrowside3::before {
    content: "";
    position: absolute;
    top: 70%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-bottom: 4px solid transparent;
    /* Adjust the size of the arrowhead */
    border-left: 8px solid var(--hieborder);
    /* Adjust the color and width of the arrowhead */
    transform: translateY(-58%);
  }
  .empcardside2 {
    position: absolute;
    border: 1px solid var(--hieborder);
    border-radius: 10px;
    padding: 0px 10px;
    background-color: var(--hiecard-bg);
    width: 15rem;
    font-weight: 500;
    // height: 105px;
    left: -19.8rem;
    top: -5.1rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    pointer-events: auto;
  }
  
  .empcardside2 p {
    font-size: 0.7rem;
  }
  
  .empcardside2 img {
    width: 4rem;
    border-radius: 5px;
    border: 2px solid var(--hieborder);
  }
  
  .empcardside3 {
    position: absolute;
    border: 1px solid var(--hieborder);
    border-radius: 10px;
    padding: 0px 10px;
    background-color: var(--hiecard-bg);
    width: 15rem;
    font-weight: 500;
    // height: 105px;
    left: -35.8rem;
    top: -9.1rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    pointer-events: auto;
  }
  
  .empcardside3 p {
    font-size: 0.7rem;
  }
  
  .empcardside3 img {
    width: 4rem;
    border-radius: 5px;
    border: 2px solid var(--hieborder);
  }