html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif !important;
  color: #000;
  margin: 0;
  padding: 0;
}

.p-component {
  font-family: "Lato", sans-serif;
}
