// WebKit (Chrome, Safari)
*::-webkit-scrollbar {
  width: 5px; /* Width of the entire scrollbar */
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners */
}
