// Typography

h1 {
  font-size: 2em;
  margin: 0;
  line-height: 1.2;
}

h2 {
  font-size: 1.5em;
  margin: 0;
  line-height: 1.3;
}

h3 {
  font-size: 1.17em;
  margin: 0;
  line-height: 1.4;
}

h4 {
  font-size: 1em;
  margin: 0;
  line-height: 1.5;
}

h5 {
  font-size: 0.83em;
  margin: 0;
  line-height: 1.6;
}

h6 {
  font-size: 0.67em;
  margin: 0;
  line-height: 1.7;
}

p {
  font-size: 1em;
  margin-bottom: 0px;
  line-height: 1.7;
  margin-top: 0px;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

ul {
  list-style-type: none;
}
