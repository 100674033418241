
.avatar-user:hover {
    background-color: #ccc;
  }
  
  @media screen and (min-width: 290px) {
    .p-tree .p-tree-container .p-treenode .p-treenode-content {
      padding: 0px;
    }
  }
  
  .p-tree .p-tree-container .p-treenode .p-treenode-content:target {
    outline: none;
  }
  
  @media screen and (min-width: 768px) {
    .profile-tab-menu {
      width: 180px;
    }
    .p-tree .p-tree-container .p-treenode .p-treenode-content {
      padding: 0px;
    }
  }
  
  @media screen and (min-width: 800px) {
    .profile-tab-menu {
      width: 200px;
    }
  }
  
  @media screen and (min-width: 900px) {
    .profile-tab-menu {
      width: 250px;
    }
  }
  
  @media screen and (min-width: 1100px) {
    .profile-tab-menu {
      width: 370px;
    }
  }
  
  .no-outline:target {
    outline: none;
  }
  
  .p-tree .p-treenode-children {
    padding: 0 0 0 0;
  }
  
  .hover-account-menu:hover {
    background: #eee;
  }
  
  .profile-menu.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    // padding: 10px;
  }
  
  .profile-menu.p-menu .p-menuitem-link {
    display: block;
  }
  
  .p-menuitem-icon.pi.pi-user-edit {
    margin-left: 30px !important;
  }
  
  .p-menuitem-icon.pi.pi-user {
    margin-left: 30px !important;
  }