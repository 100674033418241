
.MobileSidebar {
    width: 250px;
    background: white;
    height: 100%;
    position: fixed;
    z-index: 9;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .MobileSidebar .logo {
    width: 150px;
    margin: 20px 0;
  }
  
  .middle-sidebar {
    overflow-y: auto;
    height: calc(100vh - 220px);
  }
  
  .sidebar-item {
    margin-bottom: 10px;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  // .sidebar-header:hover {
  //   background: #514ba7;
  //   color: #fff;
  // }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .header-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .header-text {
    font-size: 16px;
    font-weight: 500;
  }
  
  .header-arrow {
    font-size: 20px;
  }
  
  .submenu {
    padding: 0px 20px;
  }
  .submenu li {
    list-style: none;
    margin-bottom: 5px;
  }
  
  .submenu li a.active-link {
    background: #514ba7 !important;
    color: white !important;
  }
  
  .submenu li a {
    text-decoration: none;
    color: #495057;
    font-size: 14px;
    display: block;
    padding: 10px;
    border-radius: 3px;
    transition: background 0.3s ease;
  }
  
  .submenu li a:hover {
    background: #514ba7 ;
    color: #fff;
  }
  
  .footer-sidebar {
    padding: 10px;
    background: #262657;
    color: #fff;
  }
  .footer-sidebar p {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .footer-sidebar p span {
    margin-left: 10px;
  }
  
  .mobile-p {
    font-size: 26px !important;
    margin-bottom: 0px;
    line-height: 1.7;
    margin-top: 0px;
  }
  
  @media (min-width: 320px) {
    .middle-sidebar {
      height: calc(100vh - 155px);
    }
  }
  
  @media (min-width: 768px) {
    .middle-sidebar {
      height: calc(100vh - 150px);
    }
  }
  
  @media (min-width: 1024px) {
    .middle-sidebar {
      height: calc(100vh - 180px);
    }
  }
  