@import "../themes/theme.scss";

.p-frozen-column {
  background-color: var(--background-color);
  color: var(--text-color);
}

.p-datatable {
  .p-datatable-thead {
    tr {
      th {
        background-color: var(--background-color);
        color: #fff;
        border-bottom: 1px solid var(--line-gray);
        border-top: 1px solid var(--line-gray);
        background: var(--link-color);
        // color: var(--text-color);
        font-weight: 500;

        &:first-child {
          border-top-left-radius: 14px;
          border-bottom-left-radius: 14px;
        }

        &:last-child {
          border-top-right-radius: 14px;
          border-bottom-right-radius: 14px;
        }
      }
    }
  }

  .p-datatable-tbody {
    tr {
      td {
        border: none;
        background-color: var(--background-color);
        color: var(--text-table);
        border-bottom: 1px solid var(--line-gray);
        font-size: 14px;
      }
    }
  }
}

.card {
  border: 1px solid var(--card-border);
  border-radius: 10px;
  padding: 20px;
  background-color: var(--background-color);
  box-shadow: rgb(226 226 226 / 30%) 0px 2px 8px 0px;
}

.p-tabview {
  .p-tabview-panels {
    background: var(--background-color);
  }
}

.p-inputtext {
  color: var(--text-color);
  background: var(--background-color);
  width: 100%;
}

.p-dropdown {
  color: var(--text-color);
  background: var(--background-color);

  .p-dropdown-panel {
    color: var(--text-color);
    background: var(--background-color);

    .p-dropdown-items {
      color: var(--text-color);
      background: var(--background-color);

      .p-dropdown-item {
        color: var(--text-color);
        background: var(--background-color);
      }
    }
  }

  .p-dropdown-trigger {
    color: var(--text-color);
    background: var(--background-color);
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: var(--text-color);
  background: var(--background-color);
}

// .emp_box1 {
//   background-color: var(--background-color);
//   color: var(--text-color);
// }
// .att_sheet {
//   background-color: var(--background-color);
//   color: var(--text-color);
// }
// .p-datatable .p-column-header-content{
//   justify-content: space-evenly;

// }
.dashboard {
  .card {
    padding: 25px;
    border-radius: 20px;

    &.Dcard1 {
      // border:3px solid #3dd3f9;
      background: #a8fbff;
      height: 100%;
      // border: 2px solid #026873;
      // border: 2px solid #75d7ff;
      border: 2px solid #0077b65e;
    }

    &.Dcard2 {
      // border:3px solid #3dd3f9;
      background: #ffcc96;
      height: 100%;
      border: 2px solid #e68a00;
    }

    &.Dcard3 {
      background: #8191f633;
      height: 100%;
      border: 2px solid #6a0dad4d;
    }

    &.Dcard4 {
      background: #ffeb3ba6;
      border: 2px solid #ffc966;
      height: 100%;
      // border: 2px solid #026873;
      // border: 1px solid #d38307;
      // border: 1px solid #d38307;
      // border: 2px solid #7e5109;
    }

    &.Dcard5 {
      background: #a1ffc08c;
      border: 2px solid #1375475c;
      height: 100%;
    }

    &.Dcard6 {
      background: #ffabbb;
      border: 2px solid #fa8072;
      height: 100%;
    }

    &.card1 {
      border: 2px solid #f5258d70;
      background-color: #fce4ec;
      background-repeat: no-repeat;
      height: 100%;
    }

    &.card2 {
      border: 2px solid #ffeb3b;
      background-color: #fff9c4;
      background-repeat: no-repeat;

      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
    }

    &.card3 {
      border: 2px solid #1388a6;
      background-color: #03a9f459;
      // background-image: url("../../assets/images/procard.svg");
      background-repeat: no-repeat;
      background-size: cover;
      // height: 246px;
      height: 100%;
    }

    &.card4 {
      border: 2px solid #ff9800;
      background-color: #ffc9a7;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
    }

    &.card5 {
      border: 2px solid #4fb7b7;
      background-color: #72f3f3;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
    }

    &.card6 {
      border: 3px solid #aed4fb;
      background-color: #c7e0fb;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
    }

    .icon {
      margin: 0;
      padding: 0;
      border-radius: 15px;
      width: 50px;
      height: 50px;
      text-align: center;
      font-size: 25px;
    }

    .title {
      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: #000;
        margin-bottom: 10px;
      }

      p {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    .text {
      font-weight: 600;
      color: #575757;
    }

    .dash-content {
      .c-left {
        h5 {
          font-size: 1rem;
          color: #6c6c6c;
          font-weight: 400;
        }

        p {
          font-size: 1.2em;
          font-weight: 600;
          margin-left: 10px;
        }
      }

      .c-lefts {
        .head1 {
          width: 80%;
          font-weight: 500;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #000;
        }

        p {
          font-weight: 500;
          margin-left: 20px;
          color: #6c6c6c;
        }
      }
    }
  }
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-btn-primary {
  background: #514ba7;
}

.p-column-filter-matchmode-dropdown {
  display: none;
}

.p-column-filter-operator {
  display: none;
}

.p-column-filter-operator-dropdown {
  display: none;
}

.p-column-filter-add-rule {
  display: none;
}

.p-column-filter-buttonbar {
  display: none;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 0.1rem;
}

.p-column-filter-menu-button:hover {
  color: #ffffff !important;
}

.p-column-filter-menu-button {
  color: #ffffff !important;
}

.p-column-filter-menu-button:hover {
  border-color: transparent;
  background: #6777ef !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #6777ef !important;
}

.taskdetail .p-dialog-content {
  padding: 1.2rem;

  border-radius: 15px;
}

.css-13cymwt-control {
  padding: 4px !important;
  border-radius: 6px !important;
}

.calender-project .p-button {
  background: white;
  color: black;
  border: 1px solid #d1d5db;
  border-left: none;
}

.rbc-today {
  background-color: #e8daf4;
}

// #17A2B8
.rbc-calendar {
  height: 676px;
  padding: 18px;
  background: white;
  border: none !important;
}

.rbc-event {
  background-color: #03a9f3;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #03a9f3;
  color: #fff;
}

.rbc-event-label {
  font-size: 12px;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 17px;
  font-weight: 700;
}

.rbc-toolbar {
  margin-top: 12px;
  gap: 12px;
  align-items: center;
}

.rbc-header + .rbc-header {
  color: white;
  background: #9675ce;
  border-left: 1px solid #9675ce;
}

.rbc-header {
  background: #9675ce;
  color: white;
  border-bottom: 1px solid #9675ce;
}

.rbc-toolbar button {
  border: 1px solid #9675ce;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: #9675ce !important;
  color: white;
  border-color: #9675ce;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active:hover {
  color: white;
  background-color: #ffe3e3;
}

.rbc-toolbar button.rbc-active:hover {
  background-color: #9675ce;
  color: black;
  border-color: #9675ce;
}

.rbc-toolbar button:hover {
  color: black;
  background-color: #ffe3e3;
}

.col-3 {
  width: 23% !important;
}

.col-6 {
  width: 49% !important;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  padding: 3px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  padding: 3px;
}
.react-tel-input .form-control {
  width: 100%;
  height: 40px;
}

.high-priority {
  color: red;
  border: 2px solid red;
  background-color: white;
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem #00000026;
}

.medium-priority {
  color: #fd9d17;
  border: 2px solid #fd9d17;
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem #00000026;
}

.low-priority {
  color: green;
  border: 2px solid green;
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 0.5rem 1rem #00000026;
}

.green-text {
  color: green;
  font-weight: 600;
}
.filter-buttons {
  display: flex;
  margin-right: 10px;
}

.filter-button1 {
  color: #33bfff;
  background-color: #d2dfff;
  border: 2px solid #33bfff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  width: fit-content;
}

.filter-button2 {
  color: #eb78e7;
  background-color: #ffe5fe;
  border: 2px solid #eb78e7;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  width: fit-content;
}

.filter-button3 {
  color: #ff6633;
  background-color: #fff7f5;
  border: 2px solid #ff6633;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  width: fit-content;
}
.filter-button4 {
  color: #fd9d17;
  background-color: #fff;
  border: 2px solid #fd9d17;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  width: fit-content;
}
.filter-button5 {
  color: #9000ff;
  background-color: #f4e8ff;
  border: 2px solid #c080ff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  width: fit-content;
}
.filter-button6 {
  color: #2ee5c9;
  background-color: #f4fdfb;
  border: 2px solid #2ee5c9;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  width: fit-content;
}
.filter-button.active {
  background-color: #007bff;
  color: white;
}

.filter-button:hover {
  background-color: #ddd;
}

.p-password-show-icon {
  margin-top: 0px;
}

.fi-bs-plus {
  margin-right: 8px;
}

.fi-br-filter {
  margin-right: 8px;
}

.apply-button {
  padding: 8px;
  margin-left: 6px;
  margin-right: 6px;
}
.cancel-button {
  padding: 8px;
}

.permision-card {
  display: flex;
  gap: 0px 22px;
  .card {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    .img-permi {
      .permi-img {
      }
    }
    .permi-text {
      margin-left: 1rem;
      h3 {
        font-weight: 400;
      }
      p {
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.payslip {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .rbc-calendar {
    padding: 0;
  }
  .ant-layout {
    margin-left: 0px !important;
  }
}

.p-datatable-thead {
  background-color: #6777ef !important;
}

@media screen and (max-width: 652px) {
  .p-paginator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    flex-wrap: nowrap;
    border-bottom: none;
  }

  .p-paginator .p-paginator-pages {
    display: none;
  }
}

@media screen and (max-width: 340px) {
  .p-paginator-bottom.p-paginator.p-component {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
  }
  .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
    position: absolute;
    top: 100%;
  }
}

.link-without-color {
  color: inherit;
  text-decoration: none;
}

.link-without-color:hover {
  color: #333;
}
.p-fieldset-legend .p-unselectable-text {
  background-color: #f5f5f5 !important;
}

.p-inputnumber.p-component.p-inputwrapper {
  width: 100%;
}

.p-inputnumber-input {
  width: 100% !important;
}

.p-tooltip .p-tooltip-text {
  background-color: white !important;
  color: black !important;
}

.p-dropdown.p-component.p-inputwrapper.reporting-manager {
  width: 100% !important;
}

@media only screen and (max-width: 333px) {
  .p-tabmenuitem .p-menuitem-link {
    padding: 10px;
  }
}

@media (max-width: 521px) {
  .p-steps-title {
    white-space: wrap;
    text-align: center;
  }
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-wrapper .search-icon {
  position: absolute;
  left: 10px;
  font-size: 16px;
  color: #888;
}

.input-wrapper .search-input {
  padding-left: 30px;
}

.dataTableHiddenFooterBg.p-datatable .p-datatable-footer {
  background-color: #ffffff !important;
  display: flex;
  justify-content: center;
  border: none;
}


.appointmentBar.p-scrollpanel .p-scrollpanel-bar:focus-visible {
  box-shadow: 0 0 0 0.2rem transparent;
}

.appointmentBar .p-scrollpanel-bar {
  background-color: transparent;
}

.appointmentBar.p-scrollpanel .p-scrollpanel-bar {
  background-color: transparent;
  border: 0 transparent;
}

.p-menuitem-selected {
  // background: #9cd6ffba;
  background: #e6f4ff;
}

.employee-details .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #2196f3;
  color: #2196f3;
}

.employee-details .p-tabview .p-tabview-nav li .p-tabview-nav-link{
  border-width: 0 0 3px 0;
}

.employee-details .p-unselectable-text a {
  color: #626161;
}

.employee-details .p-tabview .p-tabview-nav li .p-tabview-nav-link{
  padding: 4px 8px;
}

.ant-menu-title-content{
  flex-grow: 1;
}

.vertical-text-Memo {
  display: flex;
  flex-direction: column;
  align-items: center;
}