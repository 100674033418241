.holiday-cal .rbc-month-header {
    background: #7c7cf1;
    color: #fff;
    font-weight: 500 !important;
}

.holiday-cal .rbc-header {
    border: none !important;
    padding: 10px !important;
}

.holiday-cal .rbc-off-range-bg {
    background: #FFFFFF !important;
}

//   .rbc-button-link {
//     font-size: medium;
//     margin: 5px !important;
//     border: 1px solid #161616 !important;
//     padding: 5px !important;
//     // border-radius: 50%;
//   }

.holiday-cal .rbc-event {
    background: #D7ECF7 !important;
    color: black !important;
    font-weight: 400 !important;
}

.holiday-cal .rbc-today {
    background: #C8B6E6 !important;
}

.holiday-cal .rbc-toolbar {
    flex-direction: row;
}

.holiday-cal .rbc-toolbar .rbc-btn-group:nth-last-child(1) {
    display: none !important;
}

.holiday-cal .rbc-toolbar-label {
    margin-top: 6px;
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.holiday-cal .rbc-toolbar .rbc-btn-group:nth-last-child(3) button {
    background: #E6E6FA;
    font-weight: 600;
}

.holiday-cal .rbc-toolbar .rbc-btn-group:nth-last-child(3) button:active {
    background: #E6E6FA;
    font-weight: 600;
}

.holiday-cal .rbc-toolbar .rbc-toolbar-label {
    font-size: 22px !important;
    text-align: center;
}


@media screen and (max-width:600px) {
    .holiday-cal .rbc-toolbar {
        flex-direction: column;
        justify-content: space-between !important;

    }
}

.holiday-cal .custom-event {
    position: relative;
    display: inline-block;
  }
  
  .holiday-cal .custom-tooltip-content {
    padding: 10px;
    font-size: 14px;
    color: #8894A6 !important;
  }
  