@media (min-width: 600px) and (max-width: 1200px) {
    .dashboard{
        .card{
            .title{
                h3{
font-size: 0.7rem;
                }
                p{
font-size: 1rem;
                }
            }
            .icon{
                width: 35px;
                height: 35px;
                text-align: center;
                font-size: 18px;
            }
        }
    }
  }
  