
:where(.css-dev-only-do-not-override-1rqnfsa).ant-layout .ant-layout-sider-trigger{
    background: rgb(81, 75, 167);

}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light .ant-menu-item-selected {
    background-color: #514ba7;
}
:where(.css-dev-only-do-not-override-1rqnfsa).ant-menu-light .ant-menu-item-selected{
    color:white;
}
::where(.css-dev-only-do-not-override-1rqnfsa).ant-btn-primary {
    background: #514ba7;
}