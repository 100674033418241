.pending {
  .card {
    padding: 0px;
    .line {
      border-bottom: 2px solid var(--line-gray);
      margin: 0px 20px;
      padding: 10px 0px;
      p {
        span {
          font-weight: 600;
        }
      }
    }
  }
}
