.form-container1 {
  display: flex;
align-items: center;
justify-content: center;
}

.image-container1 {
flex:1 1 35rem;
}


.image-container1 img {
  width: 100%;
  height: 100%;
}

.form-content1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1 1 35rem;
}

.form-content1 form {
  width: 100%;
  max-width: 320px;
}

.form-content1 h4 {
  margin-bottom: 30px;
  font-size: 28px;
}

.input-container1 {
  margin-bottom: 20px;
}

/* .p-button {
      width: 100%;
  } */

/* .main {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  text-align: center;
} */

h1 {
  color: #4caf50;
}

label {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
  color: #555;
  font-weight: bold;
}

input {
  display: block;
  width: 100%;
  /* margin-bottom: 15px; */
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-btn1 {
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: none;
  color: white;
  cursor: pointer;
  background-color: #2a9df4;
  width: 100%;
  font-size: 16px;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-icon-field-right {
  width: 100% !important;
}

@media screen and (max-width: 660px) {
  .image-container1 {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .image-container1 {
    flex:0 0 35rem;
    }
    .form-content1 {
      flex:0 0 40rem;
    }
    .form-content1 form {
      width: 100%;
      max-width: 370px;
    }
    .form-container1 {
      display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    gap:5rem;
    }
}

.forgotPassword:hover {
  color: #55a6f7 !important;
}
