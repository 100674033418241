@import "~@flaticon/flaticon-uicons/css/all/all";

.input-flex-col {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.input-flex-col > div {
  flex-grow: 1;
  min-width: 45%;
}

.input-grid-col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 16px;
  place-content: center;
}

@media screen and (max-width: 700px) {
  .input-flex-col > div {
    width: 100%;
  }
}
